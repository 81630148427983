import React, { useMemo } from "react";
import styles from "./MobileMenu.module.scss";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { RiHome5Fill } from "react-icons/ri";
import { FaCoins, FaHeadphones, FaUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import nouser from "../../../../assets/web/nouser.svg";
import mixpanel from "mixpanel-browser";
import HamburgerMenu from "../hamburgerMenu/HamburgerMenu";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { Setting } from "../../../../interfaces/borrower";
import {
  updateBorrowerDetailsAction,
  updateWebAggregatorSettingsAction,
} from "../../../../redux/actions";

interface SidebarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function MobileMenu({ open, setOpen }: SidebarProps) {
  const location = useLocation();
  const history = useHistory();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const { courseDescription, courseAmount } = borrowerDetails;

  const requireEquityContribution = useMemo(() => {
    const setting = webAggregatorState?.data?.find(
      (settings: Setting) =>
        settings.setting_name?.toLowerCase() === "require_equity_contribution",
    );
    return setting?.setting_value === "1";
  }, [webAggregatorState]);

  const handleNavigation = (path: string) => {
    setOpen(false);
    document.body.style.overflow = "auto"; // Re-enable scrolling
    history.push(path);
  };

  const handleLogout = () => {
    document.body.style.overflow = "auto"; // Re-enable scrolling
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("firstTimeLogin");
    dispatch(
      updateBorrowerDetailsAction({ courseDescription: "", courseAmount: "" }),
    );
    dispatch(updateWebAggregatorSettingsAction({}));
    history.push(`/${userInfo?.aggregator_name}/login`);
    mixpanel.reset();
  };

  return (
    <>
      <div className={`${open && styles.overlay}`}></div>
      <div className={`${styles.mobile_menu_container} ${open && styles.open}`}>
        <header className={styles.overview_header}>
          <div className={styles.hamburger}>
            <HamburgerMenu open={open} setOpen={setOpen} />
          </div>
          <img src={nouser} alt="" className={styles.avatar} />
          <h1 className={styles.greeting}>Hi {userInfo?.first_name}👋🏽</h1>
        </header>
        <nav className={styles.sidebar}>
          <ul className={styles.list_container}>
            <li className={styles.list_child}>
              <NavLink
                to={
                  requireEquityContribution && courseDescription && courseAmount
                    ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}`
                    : `/${userInfo?.aggregator_name}`
                }
                activeClassName={
                  location.pathname === `/${userInfo?.aggregator_name}` ||
                  location.pathname ===
                    `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}`
                    ? styles.selected
                    : ""
                }
                onClick={() => {
                  const routeToNavigate =
                    requireEquityContribution &&
                    courseDescription &&
                    courseAmount
                      ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}`
                      : `/${userInfo?.aggregator_name}`;
                  handleNavigation(routeToNavigate);
                }}
              >
                <div className={styles.list_child_wrap}>
                  <RiHome5Fill className={styles.icon} />
                  <span>Home</span>
                </div>
              </NavLink>
            </li>
            <li className={styles.list_child}>
              <NavLink
                to={
                  requireEquityContribution && courseDescription && courseAmount
                    ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/loan`
                    : `/${userInfo?.aggregator_name}/loan`
                }
                activeClassName={styles.selected}
                onClick={() => {
                  const routeToNavigate =
                    requireEquityContribution &&
                    courseDescription &&
                    courseAmount
                      ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/loan`
                      : `/${userInfo?.aggregator_name}/loan`;

                  handleNavigation(routeToNavigate);
                }}
              >
                <div className={styles.list_child_wrap}>
                  <FaCoins className={styles.icon} />
                  <span>Loans</span>
                </div>
              </NavLink>
            </li>
            <li className={styles.list_child}>
              <NavLink
                to={
                  requireEquityContribution && courseDescription && courseAmount
                    ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/profile`
                    : `/${userInfo?.aggregator_name}/profile`
                }
                activeClassName={styles.selected}
                onClick={() => {
                  const routeToNavigate =
                    requireEquityContribution &&
                    courseDescription &&
                    courseAmount
                      ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/profile`
                      : `/${userInfo?.aggregator_name}/profile`;

                  handleNavigation(routeToNavigate);
                }}
              >
                <div className={styles.list_child_wrap}>
                  <FaUser className={styles.icon} />
                  <span>Profile</span>
                </div>
              </NavLink>
            </li>
            <li className={styles.list_child}>
              <NavLink
                to={
                  requireEquityContribution && courseDescription && courseAmount
                    ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/contact-us`
                    : `/${userInfo?.aggregator_name}/contact-us`
                }
                activeClassName={styles.selected}
                onClick={() => {
                  const routeToNavigate =
                    requireEquityContribution &&
                    courseDescription &&
                    courseAmount
                      ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/contact-us`
                      : `/${userInfo?.aggregator_name}/contact-us`;

                  handleNavigation(routeToNavigate);
                }}
              >
                <div className={styles.list_child_wrap}>
                  <FaHeadphones className={styles.icon} />
                  <span>Contact Us</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>

        <button onClick={handleLogout}>
          <IoMdLogOut className={styles.icon} />
          <span>Logout</span>
        </button>
      </div>
    </>
  );
}

export default MobileMenu;
