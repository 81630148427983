import React, { useEffect, useRef, useState } from "react";
import embedLogo from "../../../assets/web/Embed.svg";
import scene1v4 from "../../../assets/web/SCENE1v4.svg";
import styles from "./Login.module.scss";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { encrypt } from "../../../helpers/encryptor";
import { add } from "date-fns";
import { useParams } from "react-router-dom";
import { Alert, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  updateBorrowerDetailsAction,
  updateWebAggregatorSettingsAction,
} from "../../../redux/actions";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../helpers/mixpanel";
import { Setting } from "../../../interfaces/borrower";

const schema = yup.object().shape({
  email: yupValidators.email,
  password: yupValidators.password,
});

interface LoginCredentials {
  email: string;
  password: string;
}

function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [aggregatorInfo, setAggregatorInfo] = useState<any>(null);
  const isBankStatementConnectedRef = useRef({
    bank_statement_connected: null,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const { courseAmount, courseDescription, partnerName } = useParams();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (Login.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: Login.tsx`, {
      page: "Login.tsx - (Web)",
    });
  }, []);

  async function validateAggregator(shortName: string) {
    try {
      const response = await getData(
        `${apiEndpoints.validateAggregator}?shortName=${shortName}`,
      );

      setAggregatorInfo(response.data);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (Login.tsx)",
        },
      });
    }
  }

  useEffect(() => {
    validateAggregator(partnerName);
  }, [partnerName]);

  const handlePostLoginRouting = (
    requireEquityContribution: boolean,
    courseAmount: string | undefined,
    courseDescription: string | undefined,
    aggregatorShortName: string,
  ) => {
    if (requireEquityContribution && courseAmount && courseDescription) {
      history.push(
        `/${aggregatorShortName}/${courseDescription}/${courseAmount}`,
      );
    } else {
      history.push(`/${aggregatorShortName}`);
    }
  };

  const onSubmit = async ({ email, password }: LoginCredentials) => {
    setError("");
    setLoading(true);

    try {
      const loginResponse = await postData(apiEndpoints.login, {
        username: email,
        password,
      });

      const ssoAuthToken = loginResponse?.sso_auth_token;
      const encryptedToken = encrypt(ssoAuthToken);
      localStorage.setItem("token", encryptedToken);

      const session_timeout = add(new Date(), {
        seconds: loginResponse?.expiresIn,
      });

      const userResponse = await getData(apiEndpoints.customerWalletDetails);

      const {
        data: {
          bvn,
          isBvnVerified,
          country,
          bankCode,
          accountName,
          phoneNumberCountryDialCode,
          phoneNumber,
          customerType,
          customerId,
          walletId,
          isDefault,
          accountStatus,
          accountNumber,
          currency,
          aggregatorId,
          isTransactionPinSet,
          isSecurityQuestionSet,
          companyRegistrationNumber,
          transferCharge,
        },
      } = userResponse;

      const encryptedBVN = encrypt(bvn);
      const encryptedWalletId = encrypt(walletId);

      const userExistResponse = await getData(
        `${apiEndpoints.checkCustomerExistsWeb}?identity_number=${bvn}&phone_number=${phoneNumber}&aggregator_id=${aggregatorId}&email=${email}&business_registration_number=${companyRegistrationNumber}`,
      );

      const webAggregatorSettings = await getData(
        `${apiEndpoints.getWebAggregatorSettings}?AggregatorId=${aggregatorId}&CountryCode=${country}`,
      );

      if (userExistResponse?.status) {
        // This will make the borrower see application page (page 2) straight away if he isn't a first time borrower
        await checkBankStatementAuthStatus({
          customer_id: userExistResponse.data?.customer_id,
          bank_statement_provider_id:
            userExistResponse.data?.bank_statement_provider_id,
          aggregator_id: aggregatorId,
        });

        if (courseDescription && courseAmount) {
          dispatch(
            updateBorrowerDetailsAction({
              courseDescription,
              courseAmount,
            }),
          );
        }

        dispatch(updateWebAggregatorSettingsAction(webAggregatorSettings));

        dispatch(
          updateBorrowerDetailsAction({
            first_name: loginResponse?.first_name,
            last_name: loginResponse?.last_name,
            email: loginResponse?.email,
            isBvnValidated: isBvnVerified,
            isEmailVerified: loginResponse?.isEmailVerified,
            phoneNoCountryDialCode: phoneNumberCountryDialCode,
            borrower_phone: phoneNumber,
            aggregator_id: aggregatorId,
            business_registration_number:
              userExistResponse.data?.business_registration_number ??
              companyRegistrationNumber,
            company_name: userExistResponse.data?.company_name,
            aggregator_name: aggregatorInfo?.shortName ?? "partnerName",
            accountName,
            bank_account_number: accountNumber,
            accountStatus,
            bank_code: bankCode,
            bank_statement_connected:
              isBankStatementConnectedRef.current.bank_statement_connected,
            bank_statement_provider:
              userExistResponse.data?.bank_statement_provider,
            bank_statement_provider_id:
              userExistResponse.data?.bank_statement_provider_id,
            bvn_number: encryptedBVN,
            currency,
            customer_id: customerId,
            customer_type: customerType,
            isDefault,
            wallet_id: encryptedWalletId,
            firstTimeLogin: loginResponse?.firstTimeLogin,
            transferCharge,
            isTransactionPinSet,
            isSecurityQuestionSet,
            session_timeout,
            country,
          }),
        );

        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            first_name: loginResponse?.first_name,
            last_name: loginResponse?.last_name,
            email: loginResponse?.email,
            customerId,
            isBvnVerified,
            isEmailVerified: loginResponse?.isEmailVerified,
            walletId: encryptedWalletId,
            session_timeout,
            country,
            currency,
            phoneNumberCountryDialCode,
            bvn: encryptedBVN,
            phoneNumber,
            isDefault,
            accountStatus,
            accountNumber,
            customerType,
            aggregatorId,
            isTransactionPinSet,
            isSecurityQuestionSet,
            transferCharge,
            business_registration_number:
              userExistResponse.data?.business_registration_number ??
              companyRegistrationNumber,
            company_name: userExistResponse.data?.company_name,
            bank_statement_provider:
              userExistResponse.data?.bank_statement_provider,
            bank_statement_provider_id:
              userExistResponse.data?.bank_statement_provider_id,
            bank_statement_connected:
              isBankStatementConnectedRef.current.bank_statement_connected,
            aggregator_name: aggregatorInfo?.shortName ?? "partnerName",
          }),
        );

        MixPanel.identify(`${loginResponse?.email}`);

        MixPanel.people.set({
          $name: `${loginResponse?.first_name} ${loginResponse?.last_name}`,
          $email: `${loginResponse?.email}`,
          phone: `${phoneNumber}`,
          "customer id": `${customerId}`,
          aggregator: `${partnerName}`,
          product: "Embed web",
          "customer type": `${customerType}`,
        });
      }

      const requireEquityContributionSetting =
        webAggregatorSettings?.data?.find(
          (settings: Setting) =>
            settings.setting_name?.toLowerCase() ===
            "require_equity_contribution",
        );

      const requireEquityContribution =
        requireEquityContributionSetting?.setting_value === "1";

      handlePostLoginRouting(
        requireEquityContribution,
        courseAmount,
        courseDescription,
        aggregatorInfo?.shortName ?? "partnerName",
      );
    } catch (error) {
      setError(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (Login.tsx)",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  async function checkBankStatementAuthStatus(data: {
    customer_id: number;
    aggregator_id: number;
    bank_statement_provider_id: number;
  }) {
    try {
      const res = await getData(
        `${apiEndpoints.checkBankStatementProviderIsLinked}?aggregator_id=${data.aggregator_id}&bank_statement_provider_id=${data.bank_statement_provider_id}&customer_id=${data.customer_id}`,
      );
      isBankStatementConnectedRef.current.bank_statement_connected =
        res?.status;
    } catch (error) {
      setError(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (Login.tsx)",
        },
      });
    }
  }

  return (
    <div className={styles.login__container}>
      <div className={styles.form__section}>
        <header className={styles.logo__header}>
          <img src={embedLogo} alt="" />
          <span className={styles.logo}>embed</span>
        </header>

        <section className={styles.greeting}>
          {error && (
            <div style={{ width: "100%", paddingBottom: "20px" }}>
              <Alert
                message={error}
                type="error"
                showIcon
                closable
                onClose={() => setError("")}
              />
            </div>
          )}
          <header>
            <h1>Welcome Back.</h1>
            <p>Sign in to continue</p>
          </header>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              type="email"
              placeholder="example@gmail.com"
              name="email"
              label="Email address"
              reference={register}
              errors={errors.email}
            />

            <CustomInputField
              type="password"
              name="password"
              placeholder="********"
              label="Password"
              hasActionButton={true}
              reference={register}
              errors={errors.password}
            />

            <Link
              to={`/${partnerName}/reset-password`}
              className={styles.forgot__password}
            >
              forgot password?
            </Link>

            <div className={styles.submit__section}>
              <div className={styles.submit__btn}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  loading={loading}
                >
                  {loading ? " Logging in..." : "Log in"}
                </Button>
              </div>
              <p>
                Don’t have an account?{" "}
                <Link
                  to={
                    courseDescription && courseAmount
                      ? `/${partnerName}/${courseDescription}/${courseAmount}/sign-up`
                      : `/${partnerName}/sign-up`
                  }
                  className={styles.sign__up}
                >
                  Sign up
                </Link>
              </p>
            </div>
          </form>
        </section>
      </div>

      <figure className={styles.svg__section}>
        <img src={scene1v4} alt="" />
        <h2>Unlock Credit that Advances your life</h2>
        <p>
          Seize the opportunity to unlock your future earnings now and fuel your
          exponential growth
        </p>
      </figure>
    </div>
  );
}

export default Login;
