import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DashboardLayout from "../../components/layout/Layout";
import { Route } from "react-router-dom";
import { ROUTES } from "../../../helpers/ROUTES";
import DashboardOverview from "../../components/dashboard/dashboardOverview/DashboardOverview";
import LoanOverview from "../../components/loan/loanOverview/LoanOverview";
import Profile from "../../components/profile/Profile";
import ContactUs from "../../components/contactUs/ContactUs";
import LoanRequest from "../../components/features/loanRequest/LoanRequest";
import RepayLoan from "../../components/features/repayLoan/RepayLoan";
import LoanHistoryDetails from "../../components/loan/loanHistoryDetails/LoanHistoryDetails";
import RepaymentSchedule from "../../components/loan/repaymentSchedule/RepaymentSchedule";
import useFetchCustomerWalletDetails from "../../../custom-hooks/useFetchCustomerWalletDetails";
import LoanRepayments from "../../components/loan/loanRepayments/LoanRepayments";
import SetTransactionPin from "../../components/features/setTransactionPin/SetTransactionPin";
import useFetchOutstandingLoanBalance from "../../../custom-hooks/useFetchOutstandingLoanBalance";
import useFetchUserExists from "../../../custom-hooks/useFetchUserExists";
import useFetchBankStatementProviderStatus from "../../../custom-hooks/useFetchBankStatementProviderLinked";
import { useFetchLoanTransactionDetails } from "../../../custom-hooks/useFetchLoanTransactionDetails";
import useFetchRepaymentSchedule from "../../../custom-hooks/useFetchRepaymentSchedule";
import { useDispatch, useSelector } from "react-redux";
import { putData } from "../../../apis/apiMethods";
import Okra from "okra-js";
import MonoConnect from "@mono.co/connect.js";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import { Setting } from "../../../interfaces/borrower";

function UserData() {
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const isUserFirstTime = localStorage.getItem("firstTimeLogin");
  const firstTimeLogin = isUserFirstTime && JSON.parse(isUserFirstTime);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentLoanOptionStep, setCurrentLoanOptionStep] = useState(0);
  const dispatch = useDispatch();
  const accountLinkSuccessRef = useRef<boolean | null>(null);
  const loanDataStore = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const requireEquityContribution = useMemo(() => {
    const setting = webAggregatorState?.data?.find(
      (settings: Setting) =>
        settings.setting_name?.toLowerCase() === "require_equity_contribution",
    );
    return setting?.setting_value === "1";
  }, [webAggregatorState]);

  const monoWidgetData = useRef({
    opened: false,
    opened_dateTimeStamp: null,
    customer_email: null,
    is_institution_selected: false,
    selected_institution_name: null,
    institution_selected_dateTimeStamp: null,
  });

  const {
    data: walletDetails,
    isLoading: isLoadingWalletDetails,
    refetch: refetchFetchWalletdetails,
  } = useFetchCustomerWalletDetails();

  const { refetch: refetchOutstandingLoanBalance } =
    useFetchOutstandingLoanBalance({
      currency: userInfo?.currency,
      customerId: userInfo?.customerId,
    });

  const {
    data: loanDetails,
    isLoading: isLoanDetailsLoading,
    refetch: isRefetchingLoanDetails,
  } = useFetchLoanTransactionDetails({
    loanRef: loanDataStore?.loan_ref,
  });

  const { data: userExistsData, isLoading: isLoadingUserExist } =
    useFetchUserExists({
      identity_number: userInfo?.bvn,
      phone_number: userInfo?.phoneNumber,
      aggregator_id: userInfo?.aggregatorId,
      email: userInfo?.email,
      business_registration_number: userInfo?.business_registration_number,
    });

  const {
    data: bankStatementLinkedStatus,
    isLoading: isLoadingBankStatementLinkedStatus,
    refetch: refetchBankStatementLinkedStatus,
  } = useFetchBankStatementProviderStatus({
    aggregator_id: userInfo?.aggregatorId,
    customer_id: userInfo?.customerId,
    bank_statement_provider_id:
      borrowerDetails?.bank_statement_provider_id ??
      userInfo?.bank_statement_provider_id,
  });

  const {
    data: repaymentScheduleData,
    isLoading: isLoadingRepaymentSchedule,
    refetch: isRefetchingRepaymentSchedule,
  } = useFetchRepaymentSchedule({
    loan_ref: loanDataStore?.loan_ref,
  });

  useEffect(() => {
    if (firstTimeLogin && !userInfo?.isTransactionPinSet) {
      const timeoutId = setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            showPinModal: true,
          }),
        );
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
    //eslint-disable-next-line
  }, []);

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return dispatch(
        updateWebSettingsAction({ errorMsg: "Update Config for Okra" }),
      );
    }
    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = React.useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return dispatch(
        updateWebSettingsAction({ errorMsg: "Update Config for Mono" }),
      );
    }

    const customer = {
      name: `${userInfo?.first_name} ${userInfo?.last_name}`,
      email: userInfo?.email,
    };

    const monoInstance = new MonoConnect({
      onClose: () => {
        console.log("closed");

        //Below appinsights is tracking the widget data
        appInsights.trackEvent({
          name: "MonoWidgetData",
          properties: {
            ...monoWidgetData.current,
            AccountLinkStatus: accountLinkSuccessRef.current,
            OutsideLoanProcess: true,
          },
        });
      },
      onLoad: () => console.log("Loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        accountLinkSuccessRef.current = true;
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          console.log("Widget is open!");
          monoWidgetData.current = {
            ...monoWidgetData.current,
            opened: true,
            opened_dateTimeStamp: data?.timestamp,
            customer_email: userInfo?.email,
          };
        } else if (eventName === "INSTITUTION_SELECTED") {
          monoWidgetData.current = {
            ...monoWidgetData.current,
            is_institution_selected: true,
            selected_institution_name: data.institution.name,
            institution_selected_dateTimeStamp: data?.timestamp,
          };
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
    //eslint-disable-next-line
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    //Call d api to store the code
    console.log({ customerId: code });
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: userInfo?.customerId,
      aggregator_id: userInfo?.aggregatorId,
    };
    try {
      dispatch(
        updateWebSettingsAction({
          isSavingProvider: true,
        }),
      );
      const response = await putData(
        apiEndpoints.saveBankstatementProviderWeb,
        reqBody,
      );
      dispatch(
        updateWebSettingsAction({
          successMsg: {
            bodyText:
              response.message ?? "Bank provider details saved successfully",
          },
        }),
      );

      refetchBankStatementLinkedStatus();
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg:
            error?.response?.data?.message ??
            "Something went wrong please try again",
        }),
      );
    } finally {
      dispatch(
        updateWebSettingsAction({
          isSavingProvider: false,
        }),
      );
    }
  };

  const handleBankStatement = () => {
    if (!isLoadingUserExist && !isLoadingBankStatementLinkedStatus) {
      if (!bankStatementLinkedStatus?.status && handleLaunchOkraWidget) {
        if (userExistsData?.bank_statement_provider?.toLowerCase() === "okra") {
          handleLaunchOkraWidget();
        } else {
          //@ts-ignore
          monoConnect.open();
        }
      }
    }
  };

  const { courseDescription, courseAmount } = borrowerDetails;

  return (
    <Fragment>
      <DashboardLayout>
        <Route
          exact
          path={
            requireEquityContribution && courseDescription && courseAmount
              ? ROUTES.WEB_DASHBOARD_PRODUCTDIVE
              : ROUTES.WEB_DASHBOARD
          }
        >
          <DashboardOverview
            walletDetails={walletDetails}
            isLoadingWalletDetails={isLoadingWalletDetails}
            refetchFetchWalletdetails={refetchFetchWalletdetails}
            userExistsData={userExistsData}
            isLoadingUserExist={isLoadingUserExist}
            isRefetchingLoanDetails={isRefetchingLoanDetails}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            handleBankStatement={handleBankStatement}
            isLoadingBankStatementLinkedStatus={
              isLoadingBankStatementLinkedStatus
            }
            bankStatementLinkedStatus={bankStatementLinkedStatus}
            currentLoanOptionStep={currentLoanOptionStep}
            setCurrentLoanOptionStep={setCurrentLoanOptionStep}
          />
        </Route>

        <Route
          path={
            requireEquityContribution && courseDescription && courseAmount
              ? ROUTES.WEB_LOAN_PRODUCTDIVE
              : ROUTES.WEB_LOAN
          }
        >
          <LoanOverview
            userExistsData={userExistsData}
            isLoadingUserExist={isLoadingUserExist}
            isRefetchingLoanDetails={isRefetchingLoanDetails}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        </Route>
        <Route
          path={
            requireEquityContribution && courseDescription && courseAmount
              ? ROUTES.WEB_PROFILE_PRODUCTDIVE
              : ROUTES.WEB_PROFILE
          }
        >
          <Profile
            walletDetails={walletDetails}
            handleBankStatement={handleBankStatement}
            isLoadingBankStatementLinkedStatus={
              isLoadingBankStatementLinkedStatus
            }
            bankStatementLinkedStatus={bankStatementLinkedStatus}
          />
        </Route>
        <Route
          path={
            requireEquityContribution && courseDescription && courseAmount
              ? ROUTES.WEB_CONTACTUS_PRODUCTDIVE
              : ROUTES.WEB_CONTACTUS
          }
        >
          <ContactUs />
        </Route>
      </DashboardLayout>

      {webStateData?.showPinModal && <SetTransactionPin />}

      {webStateData?.showLoanRequestModal && (
        <LoanRequest
          walletDetails={walletDetails}
          bankStatementLinkedStatus={bankStatementLinkedStatus}
          isLoadingBankStatementLinkedStatus={
            isLoadingBankStatementLinkedStatus
          }
          userExistsData={userExistsData}
          isLoadingUserExist={isLoadingUserExist}
        />
      )}

      {webStateData?.showLoanDetails && (
        <LoanHistoryDetails
          loanDetails={loanDetails}
          isLoanDetailsLoading={isLoanDetailsLoading}
          isRefetchingRepaymentSchedule={isRefetchingRepaymentSchedule}
          setCurrentLoanOptionStep={setCurrentLoanOptionStep}
        />
      )}

      {webStateData?.showLoanRepayments && (
        <LoanRepayments setCurrentLoanOptionStep={setCurrentLoanOptionStep} />
      )}

      {webStateData?.showRepaymentSchedule && (
        <RepaymentSchedule
          repaymentScheduleData={repaymentScheduleData}
          isLoadingRepaymentSchedule={isLoadingRepaymentSchedule}
          setCurrentLoanOptionStep={setCurrentLoanOptionStep}
        />
      )}

      {webStateData?.showLoanRepaymentModal && (
        <RepayLoan
          refetchOutstandingLoanBalance={refetchOutstandingLoanBalance}
          walletDetails={walletDetails}
          setCurrentLoanOptionStep={setCurrentLoanOptionStep}
        />
      )}
    </Fragment>
  );
}

export default UserData;
