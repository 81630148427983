import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "../../Loans.module.scss";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import CONFIG from "../../../../../../helpers/config";
import MonoConnect from "@mono.co/connect.js";
import Okra from "okra-js";
import { Alert } from "antd";
import {
  postDataWithKey,
  putDotNetDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { SuccessComponent } from "../../../SuccessComponent";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBorrowerDetailsAction,
  updateMonoWidgetDataAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { ISuccess } from "../../../../../../interfaces/success";
import { MixPanel } from "../../../../../../helpers/mixpanel";

interface WidgetBankStatementLinkageErrorScreenProps {
  handleNext: () => void;
  encryptedKey: string;
  handleLoanRequestSuccess: any;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<ISuccess>>;
  success: ISuccess;
}

function WidgetBankStatementLinkageErrorScreen({
  handleNext,
  encryptedKey,
  handleLoanRequestSuccess,
  error,
  setError,
  setSuccess,
  success,
}: WidgetBankStatementLinkageErrorScreenProps) {
  const [isSavingProvider, setIsSavingProvider] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const loanStateData = useSelector(
    (store: ReduxStoreModel) => store.loanDetailsReducer,
  );
  const monoWidgetData = useSelector(
    (state: ReduxStoreModel) => state.monoWidgetDataReducer,
  );
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "WidgetBankStatementLinkErrorScreen.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: WidgetBankStatementLinkErrorScreen.tsx`, {
      page: "WidgetBankStatementLinkErrorScreen.tsx - (Widget)",
    });
  }, []);

  const handleAction = () => {
    setError("");
    setSuccess({ ...success, headerText: "", bodyText: "" });

    handleNext();
  };

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return setError("Update Config for Okra");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      }),
    );
    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return setError("Update Config for Mono");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      }),
    );

    const customer = {
      name: `${borrowerDetails?.first_name} ${borrowerDetails?.last_name}`,
      email: borrowerDetails?.email,
    };

    const monoInstance = new MonoConnect({
      onClose: () => {
        console.log("Widget closed");
      },
      onLoad: () => console.log("Widget loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          console.log("Widget is open!");
          dispatch(
            updateMonoWidgetDataAction({
              opened: true,
              opened_dateTimeStamp: data?.timestamp,
              customer_email: borrowerDetails?.email,
            }),
          );
        } else if (eventName === "INSTITUTION_SELECTED") {
          dispatch(
            updateMonoWidgetDataAction({
              is_institution_selected: true,
              selected_institution_name: data.institution.name,
              institution_selected_dateTimeStamp: data?.timestamp,
            }),
          );
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: borrowerDetails?.customer_id,
      aggregator_id: borrowerDetails?.aggregator_id,
    };

    try {
      setIsSavingProvider(true);
      await putDotNetDataWithKey(
        apiEndpoints.saveBankStatementProvider,
        reqBody,
        //@ts-ignore
        encryptedKey,
      );
      setSuccess({
        ...success,
        bodyText: "Bank provider details saved successfully",
      });

      dispatch(
        updateBorrowerDetailsAction({
          bank_statement_connected: true,
        }),
      );
      handleCreateLoan();
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "LoanRequestStepFour.tsx" },
      });
    } finally {
      setIsSavingProvider(false);
    }
  };

  const handleBankStatementOrCreateLoan = () => {
    if (!borrowerDetails?.bank_statement_connected) {
      if (borrowerDetails?.bank_statement_provider?.toLowerCase() === "okra") {
        handleLaunchOkraWidget();
      } else {
        monoConnect.open();
      }
    }
  };

  async function handleCreateLoan() {
    setIsLoading(true);

    const formattedMonoWidgetData = JSON.stringify({
      opened: monoWidgetData?.opened,
      customer_email: monoWidgetData?.customer_email,
      opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
      is_institution_selected: monoWidgetData?.is_institution_selected,
      selected_institution_name: monoWidgetData?.selected_institution_name,
      institution_selected_dateTimeStamp:
        monoWidgetData?.institution_selected_dateTimeStamp,
    })
      .replace(/"([^"]+)":/g, "$1:")
      .replace(/"/g, "");

    const payload = {
      effectiveDate: loanStateData?.loan_effective_date,
      bvn_number: borrowerDetails?.bvn_number,
      business_registration_number:
        borrowerDetails?.business_registration_number,
      phone_number: borrowerDetails?.borrower_phone,
      product_id: loanStateData?.product_id,
      aggregator_loan_ref: loanStateData?.aggregator_loan_ref,
      loan_amount: loanStateData?.loan_amount,
      loan_tenure: loanStateData?.loan_tenure,
      repayment_start_date: loanStateData?.repayment_start_date,
      aggregator_id: borrowerDetails?.aggregator_id,
      annual_interest_rate: loanStateData?.annual_interest_rate,
      country_code: borrowerDetails?.country_code,
      customer_id: borrowerDetails?.customer_id,
      customer_bank_account: loanStateData?.customer_bank_account,
      loan_tenor_input_type: loanStateData?.loan_tenor_input_type,
      is_bankstatement_linkage_failed: false,
      bank_statement_provider_response: monoWidgetData?.opened
        ? formattedMonoWidgetData
        : "linked",
    };

    //Below appinsights is tracking the loan  data
    appInsights.trackEvent({
      name: "WidgetCreateLoanData",
      properties: payload,
    });
    try {
      const loanRequestResponse = await postDataWithKey(
        apiEndpoints.processLoan,
        payload,
        //@ts-ignore
        encryptedKey,
      );

      if (loanRequestResponse?.status) {
        setSuccessMsg(loanRequestResponse?.message);
        setShowSuccess(true);

        mixpanel.track("Get Loan", {
          distinct_id: `${borrowerDetails?.email}`,
          "loan amount": `${loanStateData?.loan_amount}`,
          "loan name": `${loanStateData?.loan_name}`,
          "loan tenor": `${loanStateData?.loan_tenure}`,
          product_id: `${loanStateData?.product_id}`,
          "loan status": `Application success`,
          "bank statement linked status": `linked`,
        });
      }

      if (handleLoanRequestSuccess) {
        handleLoanRequestSuccess(loanRequestResponse);
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: WidgetBankStatementLinkageErrorScreen },
      });
    }
    setIsLoading(false);
  }

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body={`${successMsg}`}
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
        setSuccess={setSuccess}
      />
    );
  }

  if (isLoading || isSavingProvider) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div className={styles.stepFour_container}>
      {error && (
        <div>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      {success.bodyText && (
        <div>
          <Alert
            message={success.bodyText}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess!({ ...success, bodyText: "" })}
          />
        </div>
      )}
      <div className={styles.verification_failed}>
        <div className={styles.box}></div>
        <h3>Something went wrong</h3>
        <p>
          We couldn't get your bank statement at the moment. You can try adding
          a different account
        </p>
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <button
          className="btn btn-primary btn-radius"
          onClick={handleBankStatementOrCreateLoan}
        >
          Try again
        </button>
        <button
          className="text-primary padding-none ml-3 cursor-pointer"
          onClick={handleAction}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default WidgetBankStatementLinkageErrorScreen;
