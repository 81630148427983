import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./LoanRequest.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import info_circle from "../../../../assets/web/info-circle-loan.svg";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";
import { Button, Form, Input, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";

const Option = Select.Option;

interface LoanRequestProps {
  onPrevStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

interface FormValues {
  partPaymentPercentageValue: string | number;
  loanAmount: string | number;
}

function BNPLSummaryPage({ onPrevStep, setCurrentStep }: LoanRequestProps) {
  const [form] = Form.useForm();
  const [selectedPartPayment, setSelectedPartPayment] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (BNPLSummaryPage.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: BNPLSummaryPage.tsx`, {
      page: "BNPLSummaryPage.tsx - (Web)",
    });
  }, []);

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const requireEquityContributionPercentage = useMemo(() => {
    const percentage = webAggregatorState.data.find(
      (percent) =>
        percent.setting_name?.toLowerCase() === "cost_discount_percentage",
    );

    return percentage?.setting_value;
  }, [webAggregatorState]);

  const minimum_equity_contribution_percentage = useMemo(() => {
    const amount = webAggregatorState.data.find(
      (value) =>
        value.setting_name?.toLowerCase() ===
        "minimum_equity_contribution_percentage",
    );

    return Number(amount?.setting_value);
  }, [webAggregatorState]);

  const maximum_equity_contribution_percentage = useMemo(() => {
    const amount = webAggregatorState.data.find(
      (value) =>
        value.setting_name?.toLowerCase() ===
        "maximum_equity_contribution_percentage",
    );

    return Number(amount?.setting_value);
  }, [webAggregatorState]);

  let { courseDescription, courseAmount } = borrowerDetails;

  courseDescription = courseDescription?.toString().replace(/-/g, " ");

  const discountAmount =
    requireEquityContributionPercentage &&
    courseAmount &&
    (parseFloat(requireEquityContributionPercentage) / 100) * courseAmount;

  courseAmount = discountAmount && courseAmount - discountAmount;

  function generatePercentageValues(
    min: number,
    max: number,
  ): { value: number; label: string }[] {
    const percentageValues: { value: number; label: string }[] = [];

    for (let i = min; i <= max; i += 5) {
      percentageValues.push({ value: i, label: `${i}%` });
    }

    return percentageValues;
  }

  const percentageValues = generatePercentageValues(
    minimum_equity_contribution_percentage,
    maximum_equity_contribution_percentage,
  );

  const handlePartPaymentChange = (value: number) => {
    setSelectedPartPayment(value);
  };

  const partPaymentAmount =
    selectedPartPayment && (selectedPartPayment / 100) * courseAmount;

  const roundedPartPaymentAmount = Math.ceil(partPaymentAmount * 10) / 10;

  const loanAmount =
    courseAmount && partPaymentAmount && courseAmount - partPaymentAmount;

  useEffect(() => {
    if (loanAmount) {
      const formattedLoanAmount = loanAmount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      form.setFieldValue("loanAmount", formattedLoanAmount);
    }
  }, [loanAmount, partPaymentAmount, selectedPartPayment]);

  const handlePreviousStep = () => {
    dispatch(
      updateWebSettingsAction({
        showLoanRequestModal: false,
        showLoanOptions: true,
      }),
    );
  };

  function onFinish(values: FormValues) {
    const totalCostAmount =
      loanAmount && partPaymentAmount && loanAmount + partPaymentAmount;

    dispatch(
      updateLoanDetailsAction({
        total_cost_amount: totalCostAmount,
        equity_contribution_amount: roundedPartPaymentAmount,
        description: courseDescription,
        equity_contribution_loanAmount: loanAmount,
      }),
    );

    setCurrentStep(0);
  }

  return (
    <div className={styles.productdive_container}>
      <img
        src={arrowLeft}
        alt=""
        className={styles.arrow}
        onClick={handlePreviousStep}
      />
      <header className={styles.productdive_header}>
        <h3>{courseDescription}</h3>
      </header>

      <section className={styles.productdive_loan_amount_container}>
        <span className={styles.title}>Amount</span>
        {courseAmount?.toLocaleString().split("").includes(".") ? (
          <span className={styles.credit_balance}>
            ₦<strong>{courseAmount?.toLocaleString() ?? 0}</strong>
          </span>
        ) : (
          <span className={styles.credit_balance}>
            ₦<strong>{courseAmount?.toLocaleString()}.</strong>
            00
          </span>
        )}
        {requireEquityContributionPercentage && (
          <span className={styles.discount}>
            {requireEquityContributionPercentage}% percent discount applied
          </span>
        )}
      </section>

      <section className={styles.productdive_percentage_info}>
        <img src={info_circle} alt="" />
        <span className={styles.percentage_text}>
          You are required to pay a percentage of the lump sum before your loan
          can be disbursed
        </span>
      </section>

      <Form form={form} layout="vertical" onFinish={onFinish} size="large">
        <Form.Item name="partPaymentPercentageValue" label="Your Part Payment">
          <Select onChange={handlePartPaymentChange}>
            {percentageValues.map((percent) => (
              <Option key={percent.label} value={percent.value}>
                {percent.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedPartPayment && partPaymentAmount && (
          <span className={styles.part_payment_amount}>
            Part Payment Amount: ₦{partPaymentAmount?.toLocaleString()}
          </span>
        )}
        <Form.Item
          name="loanAmount"
          label="Loan Amount"
          style={{ marginTop: "10px" }}
        >
          <Input readOnly />
        </Form.Item>

        <Button htmlType="submit" className={styles.submit_button}>
          Proceed
        </Button>
      </Form>
    </div>
  );
}

export default BNPLSummaryPage;
